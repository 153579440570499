var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filtersWeek.tattooer == null && _vm.filtersWeek.box == null)?_c('v-simple-table',{staticClass:"pb-5 mt-5",style:('min-width: 100% !important'),attrs:{"id":"tableBox"}},[_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.daysWeek),function(day){return _c('th',{key:day,staticStyle:{"cursor":"auto","text-transform":"capitalize"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"column"}},[_c('div',{staticClass:"pa-0 rounded-pill",staticStyle:{"background-color":"var(--v-primary-base)","width":"90% !important","height":"30px","color":"var(--v-gris3-base)","font-size":"12px","font-weight":"200","line-height":"30px","cursor":"default"}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "weekDay"))+" ")]),(day != _vm.today)?_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"border":"1px solid var(--v-primary-base)","height":"45px","width":"45px","line-height":"43px","color":"var(--v-primary-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")]):_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"background-color":"var(--v-primary-base)","height":"45px","width":"45px","line-height":"45px","color":"var(--v-gris3-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")])])])})],2),_vm._l((_vm.hoursRow.filter(function (h) { return h.indexOf(':30') === -1; })),function(h,i){return _c('tr',{key:h},[_c('td',[(_vm.hoursRow.filter(function (h) { return h.indexOf(':30') === -1; }).length == i + 1)?_c('span',{staticStyle:{"margin-top":"20px","position":"absolute","left":"54px"}},[_vm._v(_vm._s(+h.split(":")[0] + 1)+":"+_vm._s(h.split(":")[1]))]):_vm._e(),_c('span',[_vm._v(_vm._s(h))])]),_c('td',{attrs:{"colspan":"1","width":"1px"}}),_vm._l((_vm.daysWeek),function(day){return [(_vm.boxesHours(h, day).reserva)?_c('td',{key:day,style:(_vm.boxesHours(h, day).reserva >= 0.5
            ? _vm.boxesHours(h, day).reserva >= 0.75
              ? _vm.boxesHours(h, day).reserva == 1
                ? 'color:red; position:relative'
                : 'color:orange; position:relative'
              : 'color:yellow; position:relative'
            : 'position:relative'),on:{"click":function($event){_vm.btn = 'day';
          _vm.date = day;}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-0 ma-0"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$n(_vm.boxesHours(h, day).reserva, "percent"))+" / "+_vm._s(_vm.boxesHours(h, day).hours)+"h ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$n(_vm.boxesHours(h, day).reserva, "percent"))+" "+_vm._s(_vm.$t("ocupation"))+" / "+_vm._s(_vm.boxesHours(h, day).hours)+" "+_vm._s(_vm.$t("hours_available")))])])],1):_c('td',{key:day})]})],2)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }