<template>
  <v-simple-table
    class="pb-5 mt-5"
    id="tableBox"
    :style="'min-width: 100% !important'"
    v-if="filtersWeek.tattooer == null && filtersWeek.box == null"
  >
    <tr>
      <th colspan="1"></th>
      <th colspan="1"></th>
      <th
        :key="day"
        v-for="day in daysWeek"
        style="cursor: auto; text-transform: capitalize"
      >
        <div style="display: flex; align-items: center; flex-direction: column">
          <div
            class="pa-0 rounded-pill"
            style="
              background-color: var(--v-primary-base);
              width: 90% !important;
              height: 30px;
              color: var(--v-gris3-base);
              font-size: 12px;
              font-weight: 200;
              line-height: 30px;
              cursor: default;
            "
          >
            {{ $d(new Date(day), "weekDay") }}
          </div>
          <div
            v-if="day != today"
            class="my-2 mt-3 rounded-circle"
            style="
              border: 1px solid var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 43px;
              color: var(--v-primary-base);
              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
          <div
            v-else
            class="my-2 mt-3 rounded-circle"
            style="
              background-color: var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 45px;
              color: var(--v-gris3-base);

              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
        </div>
      </th>
    </tr>

    <tr
      :key="h"
      v-for="(h, i) in hoursRow.filter((h) => h.indexOf(':30') === -1)"
    >
      <td>
        <span
          v-if="hoursRow.filter((h) => h.indexOf(':30') === -1).length == i + 1"
          style="margin-top: 20px; position: absolute; left: 54px"
          >{{ +h.split(":")[0] + 1 }}:{{ h.split(":")[1] }}</span
        >
        <span>{{ h }}</span>
      </td>

      <td colspan="1" width="1px"></td>

      <template v-for="day in daysWeek">
        <td
          :key="day"
          v-if="boxesHours(h, day).reserva"
          :style="
            boxesHours(h, day).reserva >= 0.5
              ? boxesHours(h, day).reserva >= 0.75
                ? boxesHours(h, day).reserva == 1
                  ? 'color:red; position:relative'
                  : 'color:orange; position:relative'
                : 'color:yellow; position:relative'
              : 'position:relative'
          "
          @click="
            btn = 'day';
            date = day;
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-0 ma-0" v-on="on" v-bind="attrs">
                {{ $n(boxesHours(h, day).reserva, "percent") }} /
                {{ boxesHours(h, day).hours }}h
              </div>
            </template>
            <span
              >{{ $n(boxesHours(h, day).reserva, "percent") }}
              {{ $t("ocupation") }} / {{ boxesHours(h, day).hours }}
              {{ $t("hours_available") }}</span
            >
          </v-tooltip>
        </td>

        <td v-else :key="day"></td>
      </template>
    </tr>
  </v-simple-table>
</template>


<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "CalendarWeekTattooer",
  data() {
    return {
      boxWeek: [],
      boxWeekTotal: null,
    };
  },
  computed: {
    ...mapState("boxes", [
      "dateState",
      "tabs",
      "filtersState",
      "boxes",
      "tattooers",
      "filtersWeekState",
    ]),
    ...mapGetters("boxes", [
      "hoursRow",
      "timeTo",
      "timeFrom",
      "mins",
      "today",
      "daysWeek",
    ]),

    filtersWeek: {
      get() {
        return this.filtersWeekState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS_WEEK", value);
      },
    },

    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    date: {
      get() {
        return this.dateState;
      },
      set(value) {
        this.$store.commit("boxes/SET_DATE", value);
      },
    },
  },
  mounted() {
    this.fetchWeek();
  },

  watch: {
    tabs: {
      handler() {
        this.fetchWeek();
      },
      deep: true,
    },
    filtersState: {
      handler() {
        this.fetchWeek();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("boxes", ["showTablePercentage"]),

    fetchWeek() {
      this.loading = true;

      this.showTablePercentage({
        dates: { from: this.filtersState.from, to: this.filtersState.to },
        studio: this.$store.state.boxes.studioId,
      }).then((boxes) => {
        this.boxWeek = boxes.data;
        this.boxWeekTotal = boxes.total;
      });
    },

    boxesHours(h, day) {
      let c = this.boxWeek[day];

      if (c) {
        let n = c.filter((x) => {
          return h >= x.start_time && h < x.end_time;
        });
        h = h.split(":")[0] + ":30";
        let n_2 = c.filter((x) => {
          return h >= x.start_time && h < x.end_time;
        });

        if (n && n_2) {
          let percentage = (n.length + n_2.length) / (this.boxWeekTotal * 2);
          let hours = n.length + n_2.length - this.boxWeekTotal * 2;

          return { reserva: percentage, hours: Math.abs(hours) / 2 };
        }
      }
      return { reserva: null, colspan: 1 };
    },
  },
};
</script>

<style lang="sass" scoped>
#tableBox
  min-width: 1000px
  padding-left: 40px
  width: auto
  background-color: var(--v-gris3-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border-top: 0
  td:first-child,th:first-child
    width: 50px
  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
    position: absolute
  span

    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span

    margin-top: -22px
    margin-left: -100px
  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  tr:last-child td:first-child
    border-bottom: 0
    span:first-child

  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    padding-right: 0 !important
    padding-left: 0 !important
    border-color: var(--v-primary-base) !important

    min-width: 100px
    max-width: 100px

    text-align: center
  td
    height: 50px
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px

    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
</style>